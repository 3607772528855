import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"

function Seo({
  description,
  lang,
  meta,
  title = "",
  imagen,
  pathname,
  robots,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            autor
            image
            url
            robots
          }
        }
      }
    `
  )

  let titulo = ""
  let descripcion = ""
  let tituloRRSS = ""

  switch (pathname) {
    case "/":
      titulo = "Empresas de ciberseguridad - Bullhost"
      descripcion =
        "Bullhost, empresas de ciberseguridad en España. Ofrecemos consultorías y servicios de ciberseguridad para empresas en España. Auditorías de ciberseguridad para empresas y pymes País Vasco."
      tituloRRSS = "Empresa de ciberseguridad - Bullhost"

      break

    /*CLOUD ROBUSTO*/
    case "/cloud-robusto/":
      titulo = "Cloud robusto Bullhost"
      descripcion =
        "Centro de datos propio en Euskadi. Altas prestaciones de seguridad, física y eléctrica y equipo altamente cualificado. Bullhost, ciberseguridad para empresas y pymes en España."
      tituloRRSS = "Cloud robusto Bullhost"

      break

    /*SERVICIOS*/
    //Ciberseguridad
    // Hacking ético
    case "/servicios/ciberseguridad/hacking-etico/auditorias-ciberseguridad/":
      titulo = "Auditorías de ciberseguridad para empresas y pymes"
      descripcion =
        "Auditorías de ciberseguridad para empresas y pymes en España. Bullhost, auditorías de ciberseguridad para prevenir los riesgos en los activos críticos de tu negocio."
      tituloRRSS = "Auditorías de ciberseguridad para empresas y pymes"

      break

    case "/servicios/ciberseguridad/hacking-etico/analisis-vulnerabilidades/":
      titulo = "Ciberseguridad para empresas - Análisis de vulnerabilidades web"
      descripcion =
        "Análisis de vulnerabilidades web para certificar el grado de seguridad en la aplicación y valorar si se cumplen las politicas de seguridad de las TIC."
      tituloRRSS =
        "Análisis de vulnerabilidades web - Ciberseguridad para empresas"

      break

    case "/servicios/ciberseguridad/hacking-etico/red-team/":
      titulo = "Servicio Red Team - Ciberseguridad para empresas"
      descripcion =
        "Consultoría ciberseguridad para empresas. Simulamos un ataque dirigido a tu organización para comprobar qué posibilidad existe de tener acceso a los sistemas."
      tituloRRSS = "Servicio Red Team - Ciberseguridad para empresas"

      break

    // Defensivos

    case "/servicios/ciberseguridad/defensivos/securizacion-dominios/":
      titulo = "Empresas de ciberseguridad - Securización de dominios"
      descripcion =
        "Securizamos tu dominio para impedir que alguien se haga pasar por ti. Bullhost ciberseguridad para empresas y pymes, con las tecnologías más top del mercado. "
      tituloRRSS = "Empresas de ciberseguridad - Securización de dominios"

      break

    case "/servicios/ciberseguridad/defensivos/formacion-concienciacion/":
      titulo = "Formación en ciberseguridad para empresas"
      descripcion =
        "Servicio de concienciación y formación en ciberseguridad para empresas, el factor humano es determinante en ataques informáticos."
      tituloRRSS = "Formación en ciberseguridad para empresas"

      break

    case "/servicios/ciberseguridad/defensivos/proteccion-dato/":
      titulo = "Servicio de protección de datos - Ciberseguridad para empresas"
      descripcion =
        "Bullhost, protegemos tus datos evitando fugas de información con la tecnología de Safetica, uno de los más relevantes fabricantes."
      tituloRRSS =
        "Servicio de protección de datos - Ciberseguridad para empresas"

      break

    // Ciber Industrial
    case "/servicios/ciberseguridad/ciberseguridad-industrial/auditoria-ot-dirigida/":
      titulo =
        "Auditoría OT dirigida - Auditorías de ciberseguridad para empresas"
      descripcion =
        "Auditoría OT dirigida, evaluamos el nivel de seguridad de las barreras de protección e infraestructura de tu entorno industrial. Auditorías de ciberseguridad para empresas. "
      tituloRRSS =
        "Auditoría OT dirigida - Auditorías de ciberseguridad para empresas"

      break

    //Sistemas informáticos
    // Integración de soluciones.
    case "/servicios/sistemas-informaticos-avanzados/sistemas-informaticos/":
      titulo = "Sistemas informáticos avanzados - Servicio de hosting seguro"
      descripcion =
        "Bullhost mantiene y gestiona todas la insfraestructuras de nuestros clientes, tanto las propias en sus instalaciones como aquellas soluciones en nuestra nube."
      tituloRRSS =
        "Sistemas informáticos avanzados - Servicio de hosting seguro"

      break

    // Sistemas informáticos avanzados
    case "/servicios/sistemas-informaticos-avanzados/outsourcing-tecnologico/":
      titulo = "Outsourcing tecnológico"
      descripcion =
        "Servicio outsourcing tecnológico: Evitarás pérdidas de tiempo de aprendizaje e inactividad, ahorrarás costes en personal especializado y aumentarás la productividad."
      tituloRRSS = "Outsourcing tecnológico"

      break

    //Cloud
    case "/servicios/cloud/hosting-web/":
      titulo = "Hosting web seguro - Servicio de alojamiento web"
      descripcion =
        "Ofrecemos nuestro servicio de alojamiento y correo para proyectos web, desde el blog más sencillo hasta el ecommerce más potente. Servicio de hosting securizado."
      tituloRRSS = "Hosting web seguro - Servicio de alojamiento web"

      break

    case "/servicios/cloud/vps/":
      titulo = "Servidor virtual privado (VPS) - Cloud robusto"
      descripcion =
        "Servidor virtual privado (VPS) en nuestro cloud robusto. La solución perfecta para disfrutar de las ventajas de una máquina dedicada a un precio asequible."
      tituloRRSS = "Servidor virtual privado (VPS) - Cloud robusto"

      break

    case "/servicios/cloud/servidor-dedicado/":
      titulo = "Servidor dedicado - Servicio alojamiento web"
      descripcion =
        "Servidores dedicados, potentes equipos informáticos para garantizar al máximo la seguridad y el rendimiento de royectos exigentes o entornos críticos."
      tituloRRSS = "Servidor dedicado - Servicio alojamiento web"

      break

    case "/servicios/cloud/backup/":
      titulo = "Copia de seguridad en la nube - Servicio Backup"
      descripcion =
        "Copia de seguridad en la nube. Ofrecemos backup en la nube mediante un proceso automatizado responsable de crear una copia de seguridad online en nuestro Cloud Robusto."
      tituloRRSS = "Copia de seguridad en la nube - Servicio Backup"

      break

    //Compliance
    case "/servicios/compliance/":
      titulo = "Compliance - Empresas de ciberseguridad en País Vasco"
      descripcion =
        "Contamos con consultores con un alto expertise y reconocimiento en el mercado que garantizan la implantación o adaptación de las normativas y estándares más habituales."
      tituloRRSS = "Compliance - Empresas de ciberseguridad en País Vasco"

      break

    /*PROYECTOS*/
    case "/proyectos/":
      titulo = "Proyectos Bullhost"
      descripcion =
        "Proyectos Bullhost. Ofrecemos servicios y auditorías de ciberseguridad para empresas, servicios de hosting, almacenamiento en la nube y servicio de protección de datos."
      tituloRRSS = "Proyectos Bullhost"

      break

    /*FABRICANTES*/
    case "/fabricantes/":
      titulo = "Fabricantes Bullhost - Empresas de ciberseguridad País Vasco"
      descripcion =
        "Bullhost se erige como partner potente de las mejores marcas del mercado internacional, trabajando con los mejores fabricantes del mercado. Consultoría ciberseguridad para empresas. "
      tituloRRSS = "Fabricantes Bullhost - Empresas de ciberseguridad"

      break

    /*BLOG*/
    case "/blog/":
      titulo = "Blog Bullhost - Empresas de ciberseguridad País Vasco"
      descripcion =
        "Blog Bullhost. Servicios y auditorías de ciberseguridad para empresas y pymes, servicios de hosting, almacenamiento en la nube y servicio de protección de datos."
      tituloRRSS = "Blog Bullhost - Empresas de ciberseguridad País Vasco"

      break

    /*CONTACTO*/
    case "/contacto/":
      titulo = "Contacto Bullhost - Ciberseguridad para empresas y pymes"
      descripcion =
        "Contacta con nosotros. Auditorías y formación en ciberseguridad para empresas y pymes. Servicios de hosting, almacenamiento en la nube y servicio de protección de datos."
      tituloRRSS = "Contacto Bullhost - Ciberseguridad para empresas"

      break

    default:
      titulo = "Bullhost - Ciberseguridad para empresas"
      descripcion =
        "Ofrecemos consultoría en ciberseguridad para empresas, servicios de hosting, almacenamiento en la nube y servicio de protección de datos."
      tituloRRSS = "Bullhost - Ciberseguridad para empresas"
      break
  }

  const metaDescription = description
    ? description
    : descripcion
    ? descripcion
    : site.siteMetadata.description
  // const defaultTitle = site.siteMetadata?.title
  const image = imagen || site.siteMetadata.image
  const url = `${site.siteMetadata.url}${pathname || ""}`
  const robotsseo = robots || site.siteMetadata.robots
  const keywords =
    "empresa de seguridad informatica, empresa de ciberseguridad, servicios de seguridad informatica, ciberseguridad para empresas, seguridad informatica para empresas, auditorias de ciberseguridad, analisis de vulnerabilidad web, servicio de proteccion de datos, consultoria ciberseguridad, formación en ciberseguridad para empresas, hosting seguro, servicio alojamiento web, servicios de almacenamiento en la nube, copia de seguridad en la nube, servicios en la nube para empresas"
  const bullJsonLD = [
    {
      "@context": "http://schema.org",
      "@type": "LocalBusiness",
      name: titulo,
      description: metaDescription,
      priceRange: "€€€",
      image: image,
      telephone: "946 94 13 77",

      address: {
        "@type": "PostalAddress",
        streetAddress: "Paseo Landabarri, 2 (Edificio Cosimet)",
        addressLocality: "Leioa",
        addressRegion: "Bizkaia",
        postalCode: "48940",
      },
      url: url,
    },
    {
      "@context": "http://schema.org",
      "@type": "LocalBusiness",
      name: titulo,
      description: metaDescription,
      priceRange: "€€€",
      image: image,
      telephone: "946 94 13 77",
      address: {
        "@type": "PostalAddress",
        streetAddress: "Carrer d'Alemanya 16. Oficina 20C.",
        addressLocality: "Figueres",
        addressRegion: "Girona",
        postalCode: "17600",
      },
      url: url,
    },
  ]

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={titulo}
      titleTemplate={title ? title + " - Ciberseguridad para empresas" : titulo}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `DC.description`,
          content: metaDescription,
        },
        {
          name: `DC.title`,
          content: titulo,
        },
        {
          name: `DC.keywords`,
          content: keywords,
        },
        {
          name: `keywords`,
          content: keywords,
        },
        {
          property: `og:title`,
          content: tituloRRSS,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: url,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          property: `og:site_name`,
          content: "Bullhost",
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:image`,
          content: image,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.autor || ``,
        },
        {
          name: `twitter:title`,
          content: tituloRRSS,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: "theme-color",
          content: "#1f1f1f",
        },
        {
          name: "robots",
          content: robotsseo,
        },
        {
          itemprop: "name",
          content: tituloRRSS,
        },
        {
          itemprop: "description",
          content: metaDescription,
        },
        {
          itemprop: "image",
          content: image,
        },
      ].concat(meta)}
      link={[
        {
          rel: "icon",
          type: "image/png",
          href: "/img/favicon-blanco.png",
        },
        {
          rel: "apple-touch-icon",
          type: "image/png",
          href: "/img/favicon-blanco.png",
        },
        {
          rel: "canonical",
          href: url,
        },
      ]}
    >
      <script type="application/ld+json">{JSON.stringify(bullJsonLD)}</script>
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `es-ES`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  image: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  link: PropTypes.arrayOf(PropTypes.object),
}

export default Seo
